.environment {
    display: flex;
    flex-direction: column;
    background-color: #998DC4;
    color: white;
    justify-content: center;
    align-items: center;
    h2 {
        font-size: 36px;
        font-family: "Barlow";
        font-weight: 900;
        margin: 60px 0;
        color: #FECC4C;
        text-align: center;
    }
}
.envImages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    column-gap: 48px;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}

.envSingleImage {
    margin: 20px 0;
    @media screen and (max-width: 768px) {
        // flex: 1 1 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}