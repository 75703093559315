.locationWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
}

.title {
    display: flex;
    position: relative;
    left: -24%;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        left: -15%;
        font-size: 12px;
        img {
            width: 25%;
        }
    }
}

.locations {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    max-width: 80%;
    gap: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}

.location {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    h1 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        font-size: 24px;
        color: #998DC4;
        margin: 10px 0;
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }
}

.address {
    display: flex;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-weight: 400;
    font-size: 16px;
    gap: 12px;
    margin: -12px 0;
}

.phone {
    display: flex;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-weight: 400;
    font-size: 16px;
    gap: 12px;
    margin: -12px 0;
}