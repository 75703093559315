.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.desc {
    text-align: center;
    max-width: 78rem;
    margin-bottom: 36px;
    h1 {
        font-family: "Barlow";
        font-weight: 900;
        font-size: 36px;
        color: #998DC4;
        margin-top: 64px;
    }
    p {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 20px;
        color: #000000CC;
        margin: 36px 0;
    }
}