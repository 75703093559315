.bannerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #B3A9D6;
}

.title {
    display: flex;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    margin-bottom: -20px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.subtitle {
    display: flex;
    flex-direction: column;
    font-family: "Baloo Thambi 2";
    color: #FFF;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: -10px;
        color: #FECC4C;
    }
    p {
        font-weight: 400;
        font-size: 20px;
    }
    span {
        font-weight: 600;
    }
    @media screen and (max-width: 768px) {
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
        }
    }
}

.summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    background-color: #FFF;
    width: 60%;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
}

.itemsSummary { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.noItems {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 32px;
    color: #000000CC;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
}

.itemName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    h1 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        font-size: 32px;
        color: #000000CC;
    }
    @media screen and (max-width: 768px) {
        gap: 5%;
        h1 {
            font-size: 20px;
        }
    }
}

.itemQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-weight: 600;
    font-size: 32px;
    color: #998DC4;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.itemPrice {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-weight: 400;
    font-size: 32px;
    color: #000000CC;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.line {
    border: .5px solid #998DC4;
    width: 90%;
}

.subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-size: 32px;
    color: #000000CC;
    width: 80%;
    margin-bottom: -60px;
    h4 {
        justify-content: flex-start;
        font-weight: 600;
    }
    p {
        justify-content: flex-end;
        font-weight: 400;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: -40px;
    }
}

.tax {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-size: 32px;
    color: #000000CC;
    width: 80%;
    h4 {
        justify-content: flex-start;
    }
    p {
        justify-content: flex-end;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-size: 32px;
    color: #000000CC;
    width: 80%;
    h4 {
        justify-content: flex-start;
        font-weight: 600;
    }
    p {
        justify-content: flex-end;
        font-weight: 400;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    margin-bottom: 40px;
    z-index: 100;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
}

.back {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFF;
    border-radius: 30px;
    border: 2px solid #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    color: #998DC4;
    text-decoration: none;
    padding: 10px 0 10px 0;
    width: 30%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        transform: translate(4px, 4px);
        box-shadow: none;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.pay {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FECC4C;
    border-radius: 30px;
    border: 2px solid #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    color: #FFF;
    text-decoration: none;
    padding: 10px 0 10px 0;
    width: 30%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        transform: translate(4px, 4px);
        box-shadow: none;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}