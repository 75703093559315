.whyChooseWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    background-color: #fff;
    font-family: "Baloo Thambi 2";
    margin-bottom: 40px;
    h2 {
        font-size: 36px;
        font-family: "Barlow";
        font-weight: 900;
        margin-bottom: 20px;
        color: #998DC4;
        text-align: center;
    }
}

.whyChoose {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0;
    gap: 40px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.whyReason {
    display: flex;
    margin: 0 20px;
    position: relative;
    justify-content: center;
    img {
        width: 100%;
        height: 496px;
        overflow: hidden;
        border-radius: 20px;
    }
    @media screen and (max-width: 768px) {
        margin: 20px 0;
    }
}

.whyText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    background-color: #FFF;
    border-radius: 20px;
    opacity: 0.8;
    min-height: 198px;
    padding: 24px, 12px, 24px, 12px;
    width: 95%;
    text-align: center;
    h2 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        font-size: 24px;
        color: #000;
    }
    p {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 16px;
        color: #000;
    }
}