.floatWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 80px;
  margin: auto;
  background-color: #998DC4;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    height: 80px;
  }
}

.float {
  display: flex;
  justify-content: center;
  align-items: center;
}

.floatText {
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Baloo Thambi 2";
}

.floatButtons {
  display: flex;
  gap: 8rem;
  justify-content: center;
  margin: auto;
  width: 100%;
  button {
    width: 180px;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    color: #998DC4;
    @media screen and (max-width: 768px) {
      width: 170px;
    }
    background-color: #FFFFFF;
  }
}

.floatContact {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 0 40px 40px 40px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  height: fit-content;
  margin: auto;
}

.floatCross {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}

.floatBackground {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}