.aboutWrapper {
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    column-gap: 10%;
    margin: 80px 0;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 20px 0;
    }
}

.col1 {
    flex-direction: column;
    margin-bottom: 20px;
}

.col2 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 550px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 250px;
        overflow: hidden;
        img {
            display: flex;
            width: 80%;
            height: auto;
            object-fit: cover;
            object-position: bottom;
            order: 2;
        }
    }
}

.title {
    display: flex;
    position: relative;
    font-family: "barlow";
    align-items: center;
    font-weight: 900;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        font-size: 12px;
        left: 5%;
        h1 {
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: #998DC4;
        }
        img {
            width: 15%;
        }
        
    }
}

.words {
    display: flex;
    position: relative;
    max-width: 502px;
    font-family: "Baloo Thambi 2";
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
        max-width: 80%;
        font-size: 16px;
        left: 5%;
    }
}

.button {
    display: flex;
    position: relative;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    color: #FFF;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: #998DC4;
    border-radius: 30px;
    background-color: #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    padding: 8px 32px 8px 32px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        box-shadow: none;
        transform: translate(4px, 4px);
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
        left: 5%;
    }
}