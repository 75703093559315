.footerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    @media screen and (max-width: 768px) {
        height: auto;
    }
}

.text {
    display: flex;
    font-family: "Baloo Thambi 2";
    text-align: center;
    font-weight: 400;
    color: #998DC4;
    font-size: 16px;
    gap: 80px;
    span {
        font-weight: 600;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0px;
    }
}