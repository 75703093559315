.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid #e0e0e0;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.headerFlex {
    flex: 1;
    font-family: "Baloo Thambi 2";
    &:first-child {
        padding: 0 20px;
        display: flex;
        align-items: center;
    }
    &:nth-child(2) {
        display: flex;
        justify-content: center;
    }
    &:last-child {
        padding: 0 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    div {
        margin-right: 20px;
        font-size: 16px;
        font-weight: 400;
    }
    button {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 16px;
    }
    @media screen and (max-width: 768px) {
        &:nth-child(2) {
            order: -1;
        }
        &:last-child {
            flex-direction: column;
            margin: 20px auto;
            div {
                margin: auto;
            }
        }
    }
}

.location {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #333;
    margin-left: 20px;
    p {
        margin: 0;
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 12px;
    }
}