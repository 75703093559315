.bannerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    display: flex;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
    z-index: 100;
    button {
        border-radius: 30px;
        border: 3px solid #998DC4;
        background-color: #FFF;
        width: 100%;
        &:hover {
            background-color: #998DC4;
            p {
                color: #FFF;
            }
        }
        &.active {
            background-color: #998DC4;
            p {
                color: #FFF;
            }
        }
        
    }
    p {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 6px 18px;
        font-family: "Baloo Thambi 2";
        font-weight: 700;
        font-size: 24px;
        color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        max-width: 80%;
        gap: 20px;
        p {
            font-size: 16px;
        }
    }
}

.gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 40px;
    margin-bottom: 40px;
    img {
        width: 100%;
        height: auto;
        max-height: 200px;
        object-fit: cover;
    }
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        max-width: 80%;
    }
}

.mover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 60%;
    margin-bottom: 80px;
    z-index: 100;
}