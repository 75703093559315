.productWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    position: relative;
    z-index: 1001;
}

.title {
    display: flex;
    position: relative;
    left: -27.5%;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        left: -15%;
        font-size: 12px;
        img {
            width: 25%;
        }
    }
}

.buttons {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.point {
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 20px;
    border: 2px solid #998DC4;
    align-items: center;
    background-color: #FFF;
    box-shadow: 4px 4px 2px #6F5FA9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    h1 {
        text-align: center;
        font-family: "Baloo Thambi 2";
        font-weight: 700;
        font-size: 28px;
        color: #998DC4;
    }
    img {
        padding: 12px 12px 12px 12px;
    }
    &:hover {
        box-shadow: none;
        transform: translate(4px, 4px);
    }
}