.contactWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #B3A9D6;
}

.title {
    display: flex;
    position: relative;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 48px;
    gap: 12px;
    left: -11%;
    margin-bottom: -30px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        left: 0%;
        text-align: center;
        font-size: 12px;
    }
}

.join {
    text-align: start;
    border-radius: 20px;
    border: 3px solid #FFF;
    background-color: transparent;
    padding: 24px;
    width: 60%;
    h1 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        font-size: 24px;
        color: #FFF;
    }
    p {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 20px;
        color: #FFF;
        opacity: 80%;
        text-decoration: underline;
        
    }
}

.formTitle {
    width: 60%;
    p{
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 24px;
        color: #FFF;
        text-align: start;
    }
}

.contactForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 60px;
    button {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: end;
        border-radius: 30px;
        border: 2px solid #998DC4;
        background-color: #FECC4C;
        box-shadow: 4px 4px 2px #6F5FA9;
        font-family: "Baloo Thambi 2";
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        color: #FFF;
        padding: 12px 40px 12px 40px;
        margin: 10px 0;
        width: 20%;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        &:hover {
            box-shadow: none;
            transform: translate(4px, 4px);
        }
    }
    p {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #FFF;
    }
}

.contactField {
    display: flex;
    margin: 10px 0;
    width: 60%;
    input {
        padding: 12px 24px;
        border-radius: 20px;
        border: 2px solid #998DC4;
        box-shadow: 4px 4px 2px #6F5FA9;
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 20px;
        width: 100%;
    }
    textarea {
        padding: 12px 24px;
        border-radius: 20px;
        border: 2px solid #998DC4;
        box-shadow: 4px 4px 2px #6F5FA9;
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        height: 10vh;
    }
}