.bannerWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.title {
    display: flex;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.news {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    column-gap: 40px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        max-width: 80%;
        display: flex;
        flex-direction: column;
    }
}

.button {
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 20px;
    border: 2px solid #998DC4;
    align-items: center;
    background-color: #FFF;
    box-shadow: 4px 4px 2px #6F5FA9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 100;
    h2 {
        text-align: center;
        font-family: "Baloo Thambi 2";
        font-weight: 700;
        font-size: 32px;
        color: #998DC4;
        margin-bottom: -10px;
        border-top: 2px solid #998DC4;
        width: 100%;
    }
    p {
        text-align: center;
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 16px;
        max-width: 80%;
    }
    &:hover {
        box-shadow: none;
        transform: translate(4px, 4px);
    }
}

.filler {
    height: 324px;
    justify-content: center;
    align-items: center;
    width: 100%;
}