.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
  background-color: #998DC4;
}

.bannerText {
  text-align: center;
  z-index: 1;
  flex: 1;
  h1 {
    font-size: 60px;
    margin-bottom: 20px;
    font-family: "Barlow";
    font-weight: 900;
    color: #FECC4C;
  }
  button {
    width: 180px;
  }
}

.bannerButtons {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    button {
        font-family: "Baloo Thambi 2";
        color: #998DC4;
        font-weight: 400;
        font-size: 16px;
    }
}

.bannerForm {
    flex: 1;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.bannerTitle {
  font-size: 32px;
  font-weight: 400;
  font-family: "Baloo Thambi 2";
  color: white;
  margin-bottom: 24px;
  p {
    margin: 0;
  }
}

.bannerWelcome {
    font-family: "Baloo Thambi 2";
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    width: 60%;
    text-align: center;
    margin: auto;
    margin-top: 40px;
    p {
        margin: 10px 0;
    }
}

.bannerBook {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    button {
        font-family: 'Baloo Thambi 2';
        font-weight: 600;
        color: #FFFFFF;
        background-color: #FECC4C;
        width: 180px;
        font-size: 16px;
        @media screen and (max-width: 480px) {
            width: 140px;
            height: 56px;
        }
    }
}

.bannerPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 0 0px 40px 0px;
    transform: translate(-50%, -50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    height: fit-content;
    margin: auto;
    @media screen and (max-width: 480px) {
        width: 100%;
        position: fixed;
        height: 100%;
    }
    h1 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        color: #998DC4;
        font-size: 16px;
        margin: 0 0;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
}

.bannerBlurBackground {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.bannerCross {
    position: absolute;
    top: 5%;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000;
}

.message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    p {
        text-align: center;
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        font-size: 16px;
        color: #FECC4C;
        margin: 0 0;
    }
}