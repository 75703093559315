.headerWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.bar {
  display: flex;
  flex-direction: column;
  background-color: #998dc4;
  width: 6%;
  height: 100vh;
}

.icon {
  display: inline-block;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 6%;
  height: 10%;
  &:first-child {
    display: flex;
    justify-items: center;
    align-items: center;
    border-bottom: 2px solid #fff;
  }
  &:nth-child(3) {
    display: flex;
    justify-items: center;
    align-items: center;
    border-top: 2px solid #fff;
    bottom: 10%;
  }
  &:last-child {
    display: flex;
    justify-items: center;
    align-items: center;
    border-top: 2px solid #fff;
    bottom: 0%;
  }
}

.image,
.hoverImage {
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: absolute;
}

.hoverImage {
  opacity: 0;
  transform: rotate(0deg);
}

.icon:hover .image {
  transform: rotate(20deg);
  opacity: 0;
}

.icon:hover .hoverImage {
  transform: rotate(0deg);
  opacity: 1;
}

.title {
  display: flex;
  position: absolute;
  writing-mode: vertical-lr;
  transform: scale(-1);
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 6%;
  top: 10%;
  a {
    font-family: "barlow";
    font-weight: 900;
    font-size: 60px;
    color: #fecc4c;
    text-decoration: none;
  }
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25%;
  right: 5%;
}

.login,
.loginHover {
  transition: opacity 0.3s ease;
  position: absolute;
}

.loginHover {
  opacity: 0;
}

.loginButton:hover .login {
  opacity: 0;
}

.loginButton:hover .loginHover {
  opacity: 1;
}

.cartButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15%;
  right: 5%;
}

.cart,
.cartHover {
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: absolute;
}

.cartHover {
  opacity: 0;
  transform: rotate(0deg);
}

.cartButton:hover .cart {
  transform: rotate(20deg);
  opacity: 0;
}

.cartButton:hover .cartHover {
  transform: rotate(0deg);
  opacity: 1;
}

.mobileWrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.mobileIcon {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: #998dc4;
  width: 16%;
  aspect-ratio: 1/1;
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #fff;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
