.bannerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #b3a9d6;
}

.title {
  display: flex;
  font-family: "barlow";
  font-weight: 900;
  align-items: center;
  color: #fecc4c;
  font-size: 28px;
  gap: 12px;
  margin-bottom: -40px;
  h1 {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #998dc4;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.subtitle {
  display: flex;
  flex-direction: column;
  font-family: "Baloo Thambi 2";
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 1%;
  h2 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: -10px;
  }
  p {
    font-weight: 400;
    font-size: 20px;
  }
  span {
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}

.packages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  margin-bottom: -20px;
  @media screen and (max-width: 768px) {
    width: 80%;
    gap: 10%;
    margin-bottom: 0px;
  }
}

.increment {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 60px;
  button {
    display: flex;
    border-radius: 50%;
    border: 2px solid #998dc4;
    box-shadow: 4px 4px 2px #6f5fa9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 100;
    font-family: "Baloo Thambi 2";
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    background-color: #fecc4c;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 54px;
    width: 54px;
    &:hover {
      transform: translate(4px, 4px);
      box-shadow: none;
    }
  }
  p {
    font-family: "Baloo Thambi 2";
    font-size: 36px;
    font-weight: 700;
    color: #fecc4c;
    width: 54px;
    height: 54px;
  }
  @media screen and (max-width: 768px) {
    gap: 5%;
    button {
      font-size: 24px;
    }
    p {
      font-size: 24px;
      justify-content: center;
      align-content: center;
      text-align: center;
    }
  }
}

.button {
  border-radius: 10px;
  border: 2px solid #998dc4;
  box-shadow: 4px 4px 2px #6f5fa9;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    min-width: 70%;
  }
}

.buttonText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  height: 80px;
  width: 360px;
  @media screen and (max-width: 768px) {
    padding: 0 10px 0 10px;
    width: 90%;
  }
}

.buttonLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: "Baloo Thambi 2";
    font-weight: 600;
    font-size: 64px;
    color: #998dc4;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
    }
    img {
      width: 40%;
    }
  }
}

.buttonRight {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    font-family: "Baloo Thambi 2";
    font-weight: 600;
    font-size: 36px;
    color: #000000cc;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 20px;
    }
  }
}

.note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 3px solid #fff;
  background-color: transparent;
  width: 60%;
  margin: 40px 0;
  p {
    font-family: "Baloo Thambi 2";
    font-weight: 400;
    font-size: 20px;
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    min-width: 80%;
    padding: 0 10px 0 10px;
    p {
      font-size: 16px;
    }
  }
}

.addCart {
  display: flex;
  border-radius: 30px;
  border: none;
  background-color: #fecc4c;
  box-shadow: 4px 4px 2px #6f5fa9;
  font-family: "Baloo Thambi 2";
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  padding: 10px 25px 10px 25px;
  margin-bottom: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 100;
  text-decoration: none;
  &:hover {
    transform: translate(4px, 4px);
    box-shadow: none;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modalInput {
  width: 80%;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.confirmButton {
  background-color: #fecc4c;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

/* Optional: Adjust the styles for responsiveness */
@media screen and (max-width: 768px) {
  .modalContent {
    width: 90%;
  }
}
