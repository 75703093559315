.mapWrapper {
    position: relative;
    width: 100%;
    background-color: #998DC4;
    @media screen and (max-width: 768px) {
        height: 480px;
    }
}

.mapText {
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 20px;
    margin-top: 60px;
    margin-right: 8rem;
    margin-left: 8rem;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.mapLocation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-family: "Baloo Thambi 2";
    gap: 80px;
    justify-content: center;
    h2 {
        font-weight: 600;
        font-size: 20px;
        color: #FFF;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        color: #FFF;
    }
    button {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        color: #998DC4;
    }
}

.divider {
    border-bottom: 1px solid #FECC4C;
    justify-content: center;
    align-items: center;
}