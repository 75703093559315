.bannerWrapper {
  display: flex;
  flex-direction: column; /* 上下结构 */
  justify-content: center;
  align-items: center;
  padding: 100px;
  gap: 20px;
  margin: 0px;
  height: 75vh;
  z-index: 99;
}

.titleSection {
  display: flex;
  flex-direction: row; /* 改为水平排列 */
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  gap: 20px; /* 增加图标和标题之间的间距 */
}

.titleSection img {
  width: 80px; /* 适当调整熊的大小 */
  height: auto;
}

.titleSection h1 {
  font-family: "barlow";
  font-weight: 900;
  color: #fecc4c;
  font-size: 48px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #998dc4;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: left; /* 左对齐文本 */
  line-height: 1.2;
  margin: 0;
}

.leftRightWrapper {
  display: flex;
  justify-content: space-between;
  gap: 50px; /* 固定的间距 */
  width: 100%;
  max-width: 1200px; /* 限制最大宽度 */
}

.leftSection {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  width: calc(50% - 25px); /* 确保左右一致的宽度 */
  height: 60vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoutButtonContainer {
  margin-top: auto; /* 确保按钮位于最底部 */
  padding-top: 10px; /* 增加与上方内容的最小间距 */
  width: 100%; /* 按钮宽度与 leftSection 一致 */
  display: flex;
  justify-content: center;
}

.logoutButton {
  width: 100%;
  padding: 10px 20px;
  background-color: #ff6b6b;
  border: 2px solid #998dc4;
  border-radius: 13px;
  color: white;
  cursor: pointer;
  box-shadow: 4px 4px 2px #6f5fa9;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;

  &:hover {
    transform: translate(4px, 4px);
    box-shadow: none;
    background-color: #ff8585;
  }

  &:active {
    transform: translate(6px, 6px);
    box-shadow: none;
  }
}

.rightSection {
  width: calc(50% - 25px); /* 同样计算宽度，确保left和right之间有20px的间距 */
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profileImage {
  width: 80px; /* 缩小图片 */
  height: 80px; /* 缩小图片 */
  border-radius: 50%;
  border: 2px solid #998dc4;
}

.userName {
  font-family: "Baloo Thambi 2";
  font-weight: 600;
  font-size: 24px; /* 缩小字体 */
  color: #998dc4;
  margin-top: 10px;
}

.userId {
  font-size: 14px; /* 缩小字体 */
  color: #998dc4;
}

.userInfo {
  width: 100%;
  margin-bottom: 20px;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0; /* 缩小间距 */
  border-bottom: 1px solid #ddd;
}

.label {
  font-family: "Baloo Thambi 2";
  font-size: 16px; /* 缩小字体 */
  color: #998dc4;
}

.value {
  font-family: "Baloo Thambi 2";
  font-size: 16px; /* 缩小字体 */
  color: #333;
}

.verifiedIcon {
  width: 14px; /* 缩小图标 */
  height: 14px;
}

.pointsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px; /* 调整图标之间的间距 */
}

.pointsBox,
.coinsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 8px;
  background-color: #fff;
  border: 2px solid #998dc4;
  border-radius: 13px;
  box-shadow: 4px 4px 2px #6f5fa9; // 增加阴影效果
  transition: transform 0.3s ease, box-shadow 0.3s ease; // 添加交互效果

  &:hover {
    transform: translate(4px, 4px);
    box-shadow: none;
  }
}

.icon {
  width: 30px; /* 适当放大图标 */
  margin-right: 10px; /* 增加图标和文字的间距 */
}

.rightSection {
  width: calc(50% - 25px); /* 维持原有宽度 */
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 60vh; /* 设置最大高度，防止内容超出屏幕 */
  overflow-y: auto; /* 当内容超出时，出现纵向滚动条 */
}

.activityHeader {
  font-family: "Baloo Thambi 2";
  font-weight: 600;
  font-size: 22px; /* 缩小字体 */
  color: #998dc4;
  margin-bottom: 20px;
  display: flex;
  align-items: center; /* 垂直居中对齐图标和文字 */
  gap: 8px; /* 图标和文字之间的间距 */
}

.activityHistoryIcon {
  width: 25px; /* 图标大小 */
  height: 25px;
  margin-right: 10px;
}

.activityFeed {
  display: flex;
  flex-direction: column;
}

.activityDay {
  margin-bottom: 10px;
}

.activityDate {
  font-family: "Baloo Thambi 2";
  font-size: 16px;
  color: #998dc4;
  margin-bottom: 10px;
}

.activityRow {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-bottom: 1px solid #ddd; /* 添加下划线 */
  padding-bottom: 8px; /* 给下划线与内容之间增加一些间距 */
}

.activityRow .icon {
  width: 18px; /* 缩小图标 */
  margin-right: 8px;
}

.activityRow span {
  font-family: "Baloo Thambi 2";
  font-size: 16px; /* 缩小字体 */
  color: #333;
}

@media (max-width: 768px) {
  /* 针对手机等小屏幕 */
  .bannerWrapper {
    padding: 50px 20px; /* 缩小间距，适应小屏幕 */
    height: auto; /* 允许根据内容调整高度 */
  }

  .leftRightWrapper {
    flex-direction: column; /* 改为上下排列 */
    gap: 20px; /* 调整间距 */
  }

  .leftSection,
  .rightSection {
    width: 90%; /* 使左右部分宽度占据整个屏幕 */
    height: auto; /* 移除固定高度，允许根据内容调整 */
  }

  .profileImage {
    width: 60px; /* 缩小头像大小 */
    height: 60px;
  }

  .userName {
    font-size: 20px; /* 调整字体大小 */
  }

  .userId {
    font-size: 12px; /* 调整字体大小 */
  }

  .pointsBox,
  .coinsBox {
    padding: 5px; /* 缩小内边距 */
    font-size: 14px; /* 缩小文本大小 */
  }

  .activityHeader {
    font-size: 18px; /* 缩小活动标题字体 */
  }

  .activityFeed {
    font-size: 14px; /* 缩小活动内容字体 */
  }

  .titleSection img {
    width: 60px; /* 缩小熊的图片 */
  }

  .titleSection h1 {
    font-size: 36px; /* 调整标题大小 */
  }
}

.leftSection,
.rightSection {
  z-index: 99; /* 新增 z-index */
}

.logoutButtonContainer {
  z-index: 99; /* 新增 z-index */
}

.activityFeed {
  z-index: 99; /* 新增 z-index */
}
