// define the styles for the button component
.button {
  background-color: #998DC4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
  transition: background-color 0.3s;
  &:hover {
    background-color: #FECC4C;
  }
}

.buttonWhite {
  background-color: #fff;
  color: #d0b28c;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f0f0f0;
  }
}
