.bannerWrapper {
    display: flex;
    overflow: hidden;
    height: 100vh;
    position: relative;
}

.bannerWrapper iframe {
    position: absolute;
    width: 100vw;
    height: auto; 
    min-height: 100vh; 
    border: none; 
}

.logo {
    display: flex;
    position: absolute;
    right: 2%;
    top: 2%;
}

.background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.orderButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5%;
    left: 10%;
    z-index: 100;
    button {
        min-width: 30vh;
        margin: 10px 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 5px solid #FFF;
        border-radius: 30px;
        font-family: "Baloo Thambi 2";
        font-size: 32px;
        font-weight: 700;
        color: #FFF;
        background-color: transparent;
        &:hover{
            background-color: #998DC4;
            border: 5px solid #998DC4;
        }
    }
    @media screen and (max-width: 768px) {
        button {
            font-size: 24px;
            border: 4px solid #FFF;
            width: 20%;
        }
        left: 5%;
    }
}

.mobileLoginButton {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 2%;
    bottom: 25%;
    z-index: 1;
}

.mobileCartButton {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 2%;
    bottom: 12%;
    z-index: 1;
}