.container {
    display: flex;
    width: 100%;
    height: 88px;
    font-family: "barlow";
    font-weight: 900;
    font-size: 36px;
    overflow: hidden;
    white-space: nowrap;
    background: #998DC4;
    color: #fff;
    align-items: center;
    @media screen and (max-width: 768px) {
        height: 66px;
    }
}

.scroll {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    white-space: nowrap;
    animation: loop 10s linear infinite;
}

@keyframes loop {
    0% {transform: translateX(0%);}
    100% {transform: translateX(-50%);}
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 4px;
    color: #FFF;
    font-size: 36px;
    padding: 0 18px;
    margin: 0 16px;
    white-space: nowrap;
}