.bannerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #A69CCA;
}

.logo {
    display: flex;
    position: absolute;
    top: 0%;
    right: 0%;
    width: 20%;
}

.title {
    text-align: center;
    font-family: "barlow";
    font-weight: 900;
    color: #FECC4C;
    font-size: 32px;
    margin-bottom: -40px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
}

.subtitle {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: "Baloo Thambi 2";
    h2 {
        font-weight: 600;
        font-size: 24px;
        color: #FFF;
        margin-bottom: -10px;
    }
    @media screen and (max-width: 768px) {
        h2 {
            font-size: 16px;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin: 30px 0;
}

.field {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
        width: 25%;
        border-radius: 10px;
        border: 2px solid #998DC4;
        padding: 20px 30px 20px 30px;
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 16px;
        box-shadow: 4px 4px 2px #6F5FA9;
    }
    @media screen and (max-width: 768px) {
        input {
            width: 60%;
            aspect-ratio: 6/1;
            padding: 0 10px 0 10px;
        }
    }
}

.formBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.checkbox {
    display: flex;
    position: relative;
    left: -12%;
    label {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 12px;
        color: #FFF;
    }
}

.submit {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 2px solid #998DC4;
    background-color: #FECC4C;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    color: #FFF;
    padding: 10px 25px 10px 25px;
    box-shadow: 4px 4px 2px #6F5FA9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        box-shadow: none;
        transform: translate(4px, 4px);
    }
    @media screen and (max-width: 768px) {
        padding: 0 10px 0 10px;
        width: 40%;
        aspect-ratio: 4/1;
    }
}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10%;
    margin: 30px 0;
    img {
        padding: 15px;
        background-color: #FFF;
        border-radius: 10px;
        border: 2px solid #C2BFCB;
        box-shadow: 4px 4px 2px #C2BFCB;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        &:hover {
            transform: translate(4px, 4px);
            box-shadow: none;
        }
    }
}

.yesAccount {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 16px;
        color: #FFF;
    }
    a {
        text-decoration: none;
        color: #FECC4C;
    }
}