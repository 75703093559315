.advWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #B3A9D6;
}

.title {
    display: flex;
    position: relative;
    left: -24%;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    @media screen and (max-width: 768px) {
        left: -15%;
        font-size: 12px;
        img {
            width: 25%;
        }
    }
}

.points {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.point {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    font-family: "Baloo Thambi 2";
    border-radius: 20px;
    padding: 24px 24px 24px 24px;
    max-width: 75%;
    border: 2px solid #998DC4;
    box-shadow: 8px 8px 2px #6F5FA9;
    margin: 20px 0;
    h1 {
        font-weight: 600;
        font-size: 24px;
        color: #998DC4;
    }
    p {
        font-weight: 400;
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        padding-top: 0%;
        padding-bottom: 0%;
    }
}