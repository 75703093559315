.brandWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.title {
    display: flex;
    position: relative;
    left: -22.8%;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        left: -15%;
        font-size: 12px;
        img {
            width: 25%;
        }
    }
}

.timelineWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-top: 40px;
}

.timeline {
    display: flex;
    position: relative;
    width: 120%;
    transition: left 0.5s ease;
    left: 0%;
    @media screen and (max-width: 768px) {
        width: 200%;
    }
}

.mover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 60%;
    margin-bottom: 80px;
}

.left {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 2px solid #998DC4;
    background-color: #998DC4;
    color: #FFF;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 32px;
    width: 48px;
    height: 48px;
    text-align: center;
    box-shadow: 4px 4px 2px #6F5FA9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1001;
    &:hover {
        transform: translate(4px, 4px);
        box-shadow: none;
    }
}

.right {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 2px solid #998DC4;
    background-color: #998DC4;
    color: #FFF;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 32px;
    width: 48px;
    height: 48px;
    text-align: center;
    box-shadow: -4px 4px 2px #6F5FA9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1001;
    &:hover {
        transform: translate(-4px, 4px);
        box-shadow: none;
    }
}