.footer {
    height: 240px;
    font-family: "Baloo Thambi 2";
}

.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    color: #000000CC;
}

.footerTel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: #998DC4;
    button {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 16px;
        color: #998DC4;
    }
}