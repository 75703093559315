.bannerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.title {
    display: flex;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        img {
            width: 80%;
        }
    }
}

.words {
    display: flex;
    flex-direction: column;
    h2 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        font-size: 48px;
        color: #998DC4;
        margin-bottom: -10px;
    }
    p {
        font-family: "Baloo Thambi 2";
        font-weight: 400;
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        justify-content: center;
        h2 {
            font-size: 32px;
        }
        p {
            font-size: 16px;
        }
    }
}

.location {
    border-radius: 30px;
    border: 3px solid #998DC4;
    padding: 6px, 18px, 6px, 18px;
    background-color: #FFF;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    color: #998DC4;
    margin: 10px 0;
    z-index: 100;
    &:hover {
        background-color: #998DC4;
        color: #FFF;
    }
}

.contact {
    display: flex;
    position: relative;
    border-radius: 30px;
    color: #FFF;
    background-color: #998DC4;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    border: 2px solid #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    z-index: 100;
    &:hover {
        box-shadow: none;
        transform: translate(4px, 4px);
    }
}