.menuWrapper {
    display: flex;
    position: absolute;
    align-items: center;
    right: 0%;
    background-color: #B3A9D6;
    z-index: 1000;
    width: 94%;
    height: 100vh;
}

.menuLinks {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-left: 10%;
    a {
        font-family: "Baloo Thambi 2";
        font-weight: 700;
        font-size: 56px;
        color: #FFF;
        text-decoration: none;
        transition: font-size 0.3s ease;
        &:hover {
            color: #FECC4C;
            font-size: 64px;
        }
    }
}

.mobileWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    background-color: #998DC4;
    z-index: 999;
    width: 100%;
    min-height: 100vh;
}

.header {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 84%;
    right: 0%;
    aspect-ratio: 5.25/1;
    font-family: 'barlow';
    font-weight: 900;
    font-size: 40px;
    color: #FECC4C;
    text-decoration: none;
    line-height: 1.5px;
    top: 0%;
}

.mobileLinks {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10%;
    height: 100vh;
    justify-content: center;
    row-gap: 2%;
    a {
        font-family: "Baloo Thambi 2";
        font-weight: 700;
        font-size: 32px;
        color: #FFF;
        text-decoration: none;
        z-index: 1000;
    }
}

.socials {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 5/1;
    bottom: 0%;
}

.tiktok {
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px 1px 0px 0px;
    border-style: solid;
    border-color: #FFF;
    width: 100%;
    aspect-ratio: 3/1;
}

.instagram {
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px 0px 0px 1px;
    border-style: solid;
    border-color: #FFF;
    width: 100%;
    aspect-ratio: 3/1;
}