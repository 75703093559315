.cartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #B3A9D6;
    min-height: 100vh;
}

.title {
    display: flex;
    font-family: "barlow";
    font-weight: 900;
    align-items: center;
    color: #FECC4C;
    font-size: 28px;
    gap: 12px;
    margin-bottom: -40px;
    h1 {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #998DC4;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.subtitle {
    display: flex;
    flex-direction: column;
    font-family: "Baloo Thambi 2";
    color: #FFF;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: -10px;
    }
    p {
        font-weight: 400;
        font-size: 20px;
    }
    span {
        font-weight: 600;
    }
    @media screen and (max-width: 768px) {
        h2 {
            font-size: 20px;
            max-width: 90%;
        }
        p {
            font-size: 16px;
        }
    }
}

.itemList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    z-index: 100;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
}

.noItems { 
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 32px;
    color: #000000CC;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #FFF;
    border-radius: 10px;
    border: 2px solid #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    margin-bottom: 40px;
    width: 100%;
}

.buttonText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 0px 20px;
    width: 90%;
    @media screen and (max-width: 768px) {
        padding: 0 10px 0 10px;
    }
}

.buttonName {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 20%;
    h1 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        font-size: 32px;
        color: #000000CC;
    }
    @media screen and (max-width: 768px) {
        gap: 5%;
        h1 {
            font-size: 20px;
        }
        img {
            width: 40%;
        }
    }
}

.price {
    display: flex;
    justify-content: center;
    align-self: center;
    font-family: "Baloo Thambi 2";
    font-weight: 400;
    font-size: 32px;
    color: #000000CC;
    width: 10%;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.increment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 32px;
    color: #998DC4;
    width: 15%;
    .minus {
        &:hover {
            color: #000000CC;
        }
    }
    .plus {
        &:hover {
            color: #000000CC;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.trash {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    &:hover {
        opacity: 60%;
    }
}

.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    margin-bottom: 40px;
    z-index: 100;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
}

.back {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFF;
    border-radius: 30px;
    border: 2px solid #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    color: #998DC4;
    text-decoration: none;
    padding: 10px 0 10px 0;
    width: 30%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        transform: translate(4px, 4px);
        box-shadow: none;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.checkout {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FECC4C;
    border-radius: 30px;
    border: 2px solid #998DC4;
    box-shadow: 4px 4px 2px #6F5FA9;
    font-family: "Baloo Thambi 2";
    font-weight: 700;
    font-size: 24px;
    color: #FFF;
    text-decoration: none;
    padding: 10px 0 10px 0;
    width: 30%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        transform: translate(4px, 4px);
        box-shadow: none;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}