.contact {
    width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin-top: 40px;
    border-radius: 5px;
    opacity: 0.9;
    color: #D0B28C;
    padding: 20px 0;
    z-index: 1000;
    h2 {
        font-size: 32px;
        font-family: "Baloo Thambi 2";
        font-weight: 700;
        text-align: center;
        color: #998DC4;
        text-transform: capitalize;
    }
    p {
        font-size: 16px;
        font-family: "Baloo Thambi 2";
        text-align: center;
        font-weight: 400;
        margin-bottom: 20px;
        max-width: 24rem;
        color: black;
        &:nth-child(3) {
            font-weight: 600;
            font-size: 12px;
            color:#998DC4;
        }
    }
    button {
        margin-top: 10px;
        width: 100%;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
        font-family: "Baloo Thambi 2";
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        background-color: #998DC4;
    }
    @media screen and (max-width: 480px) {
        width: 100%;
        margin: auto;
    }
}

.contactForm {
    display: flex;
    flex-direction: column;
}

.contactField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
    input {
        padding: 10px;
        border: 1px solid #908AA0;
        border-radius: 5px;
        font-size: 1rem;
        font-family: "Baloo Thambi 2";
        width: 424px;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
    h1 {
        font-family: "Baloo Thambi 2";
        font-weight: 600;
        color: #998DC4;
        font-size: 16px;
        margin: 0 0;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
}