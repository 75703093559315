.reviewsWrapper {
  background-color: #998DC4;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 40px 0 60px 0;
  font-family: "Baloo Thambi 2";
  h2 {
    font-size: 36px;
    font-family: "Barlow";
    font-weight: 900;
    margin-bottom: 20px;
    color: #FECC4C;
  }
  p {
    font-size: 32px;
    font-weight: 400;
    max-width: 78rem;
    color: #FFFFFF;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
}
.reviews {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-column-gap: 40px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.singleReview {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 24px 12px 24px 12px;
  background-color: #FFFFFF80;
  border-radius: 20px;
  min-height: 194px;
  margin: 20px auto;
  text-decoration: none;
  max-width: 385px;
  h2 {
    font-family: "Baloo Thambi 2";
    color: #000000CC;
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0;
  }
  p {
    font-family: "Baloo Thambi 2";
    color: #000000CC;
    font-size: 12px;
    font-weight: 400;
    margin: 20px 0;
  }
}
